import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  mobileThresholdPixels, colors, margins, ContainerBlock,
}
  from '../home/v2/StyledComponents';

const StyledContainerBlock = styled(ContainerBlock)`
  display: flex;
  justify-content: space-evenly;
  width: 800px;
  margin: 0px auto ${margins.m};

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    width: 100%;
  }
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Span = styled.span`
  font-size: 10px;
  color: ${colors.darkGrey};
`;

const Column = (districtNumbers) => (
  <ColumnContainer>
    {districtNumbers.map((districtNumber) => (
      <Link
        key={districtNumber}
        to={`/retouche-vetement/paris/paris-0${districtNumber}/`}
        style={{ textDecoration: 'none' }}
      >
        <Span>{`Retouche vêtement Paris ${districtNumber}`}</Span>
      </Link>
    ))}
  </ColumnContainer>
);

const ParisDistricts = () => (
  <StyledContainerBlock>
    {Column([1, 2, 3])}
    {Column([4, 5, 6])}
    {Column([7, 8, 9])}
  </StyledContainerBlock>
);

export default ParisDistricts;
