import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { trackEvent } from '../../services/analytics';
import isMobile from '../../services/isMobile';
import Dots from '../home/v2/Dots';
import {
  mobileThresholdPixels, margins,
  Title2, Subtitle2, ContainerBlock, Column, LineV,
} from '../home/v2/StyledComponents';

import maduraLogo from '../../assets/partners/madura.png';
import apcLogo from '../../assets/partners/apc.png';
import isabelMarantLogo from '../../assets/partners/Isabel-Marant.png';
import lexceptionLogo from '../../assets/partners/l_exception.png';
import sezaneLogo from '../../assets/partners/sezane.png';
import elisechalminLogo from '../../assets/partners/elisechalmin.png';
import violetteSauvageLogo from '../../assets/partners/violette_sauvage.png';
import carolinaRitzlerLogo from '../../assets/partners/CarolinaRitzler.png';
import karlaOttoLogo from '../../assets/partners/karla_otto.png';
import laureDeSagazanLogo from '../../assets/partners/Laure_de_Sagazan.svg';

const partnerImages = [
  { index: 0, img: maduraLogo, alt: 'conciergerie paris' },
  { index: 1, img: apcLogo, alt: 'couturiers certifiés' },
  { index: 2, img: isabelMarantLogo, alt: 'prestataire de retouche' },
  { index: 3, img: lexceptionLogo, alt: 'service de retouches Aix Metz Marseille' },
  { index: 4, img: sezaneLogo, alt: 'meilleure retoucherie Paris' },
  { index: 5, img: violetteSauvageLogo, alt: 'meilleure retouche paris' },
  { index: 6, img: elisechalminLogo, alt: 'broderie t-shirt et pull' },
  { index: 7, img: carolinaRitzlerLogo, alt: 'service retouche Paris' },
  { index: 8, img: karlaOttoLogo, alt: 'atelier retouches' },
  { index: 9, img: laureDeSagazanLogo, alt: 'retouche de robe de mariée' },
];

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  ${(props) => props.partnersBlock && `
    width: 80px;
    height: 200px;
  `}

  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => props.partnersBlock && `
      width: 120px;
      height: 120px;
    `}
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: ${margins.m} 0px 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.s} 0px;
  }
`;

const Img = styled.img`
  max-width: 120px;
  max-height: 120px;
  animation: imgAnimation 4s infinite;

  @keyframes imgAnimation {
    from { opacity: 0; }
    25%  { opacity: 1; }
    75%  { opacity: 1; }
    to   { opacity: 0; }
  }

  @media (max-width: ${mobileThresholdPixels}) {
    max-width: 140px;
    max-height: 140px;
  }
`;

const StyledLineV = styled(LineV)`
  height: 90px;
  margin: 0px 80px;

  @media (max-width: ${mobileThresholdPixels}) {
    height: 100px;
    margin: 0px 30px;
  }
`;

const PartnerBlock = (currentIndex, relativeIndex) => {
  const index = (currentIndex + relativeIndex) % partnerImages.length;
  return (
    <Row partnersBlock>
      <Link
        to="/ils-nous-font-confiance-les-partenaires-tilli"
        onClick={() => trackEvent('click', `${partnerImages[index].img}_block-marques-partenaires`)}
      >
        <Img
          src={partnerImages[index].img}
          alt={partnerImages[index].alt}
        />
      </Link>
    </Row>
  );
};

class PartnersPager extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentIndex: 0 };
  }

  componentDidMount() {
    this.interval = setInterval(
      () => this.setState({ currentIndex: (this.state.currentIndex + 4) % partnerImages.length }),
      4000,
    );
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <ContainerBlock>
        <Column justifyContent="center" alignItems="center">
          <Title2>Marques Partenaires</Title2>
          <Dots />
          <Subtitle2>
            Tilli accompagne
            <br />
            vos marques favorites.
          </Subtitle2>
        </Column>
        <SubContainer>
          {!isMobile()
            ? (
              <Row>
                {PartnerBlock(this.state.currentIndex, 0)}
                {' '}
                <StyledLineV />
                {PartnerBlock(this.state.currentIndex, 1)}
                {' '}
                <StyledLineV />
                {PartnerBlock(this.state.currentIndex, 2)}
                {' '}
                <StyledLineV />
                {PartnerBlock(this.state.currentIndex, 3)}
              </Row>
            )
            : (
              <Row>
                {PartnerBlock(this.state.currentIndex, 0)}
                {' '}
                <StyledLineV />
                {PartnerBlock(this.state.currentIndex, 1)}
              </Row>
            )}
        </SubContainer>
      </ContainerBlock>
    );
  }
}

export default PartnersPager;
