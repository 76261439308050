import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import {
  ViewPager, Frame, Track, View,
} from 'react-view-pager';
import ProgressiveImage from 'react-progressive-image';

import routesMap from '../../Routes';
import isMobile from '../../services/isMobile';
import { trackEvent } from '../../services/analytics';
import featureSwitch from '../../services/featureSwitch';
import { toFormattedText } from '../../services/formatting';
import {
  margins, colors, mobileThresholdPixels, fontSizes,
  Column, Row, RelativePosition, ButtonContainer, Button,
} from '../home/v2/StyledComponents';
import { HeaderPhoto as HeaderPhotoComponent } from '../home/homeStyledComponents';

import '../home/frameStyle.css';

import header1 from '../../assets/headers/photo_header1_VotreCouturierDomicile.jpg';
import arrowLeft from '../../assets/headers/arrow.white.left.png';
import arrowRight from '../../assets/headers/arrow.white.right.png';
import arrowEmpty from '../../assets/headers/arrow.empty.png';
import menSuitHeaderPhoto from '../../assets/photo-header-suit.jpg';
import motherHeaderPhoto from '../../assets/header-mere-retouche.jpg';
import headerPhotoMobile from '../../assets/headerV2-small.jpg';
import maduraHeaderPhoto from '../../assets/brand/Madura.jpg';
import embroideryHeaderPhoto from '../../assets/header-broderie-retouche-veste-jeans.jpg';
import sezaneHeaderPhoto from '../../assets/brand/Sezane2.jpg';
import galeriesLafayetteHeaderPhoto from '../../assets/brand/GaleriesLafayette.jpg';
import sessunHeaderPhoto from '../../assets/brand/Sessun.jpg';

const viewPagerStyle = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
};

const Container = styled.div`
  position: absolute;
  top: 0px;
  width: 100vw;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const SubContainer = styled.div`
  position: inherit;
  width: fit-content;
  text-align: center;
  margin: 50px auto -75px auto;

  @media (max-width: ${mobileThresholdPixels}) {
    margin: 20px auto -20px;
  }
`;

const StyledRow = styled(Row)`
  width: 100vw;
  justify-content: space-between;
  @media (max-width: ${mobileThresholdPixels}) {
    margin-top: 0px;
  }
`;

const ControlContainer = styled.div`
  ${(props) => props.right ? 'right: 0px' : 'left: 0px'};
  display: flex;
  align-items: center;
  z-index: 1;
`;

const ControlImg = styled.img`
  z-index: 2;
  cursor: pointer;
  width: 30px;
  margin: ${margins.m};

  // fix image flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);

  @media (max-width: ${mobileThresholdPixels}) {
    width: 14px;
  }
`;

const Img = styled.img`
  width: 100vw;
  height: 44vw;
  object-fit: fill;

  // fix image flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);

  @media (max-width: ${mobileThresholdPixels}) {
    height: 80vh;
    max-height: 80vh;
    object-fit: cover;
  }
`;

const StyledButton = styled(Button)`
  padding: 0px 40px;
  margin: ${margins.m} 0px 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 18px 9vw;
    margin: 0px;
  }
`;

const H1Container1 = styled.div`
  margin: auto;

  // fix text flickering in Chromium-based browsers
  -webkit-transform:translate3d(0,0,0);
`;

const H1 = styled.h1`
  ${(props) => props.navy ? `color: ${colors.navy}` : `color: ${colors.white}`};
  font-family: Gotham;
  margin: 0px;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    line-Height: 0.5;
  }
`;

const Span1 = styled.span`
  font-size: ${fontSizes.x3l};
  font-weight: bold;
  text-transform: uppercase;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
    line-height: 1;
    margin-bottom: ${fontSizes.s};
  }
`;

const Span2 = styled.span`
  font-family: Roboto;
  font-size: ${fontSizes.l};
  font-weight: 500;
  ${(props) => props.navy ? `color: ${colors.navy}` : `color: ${colors.white}`};

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
  }
`;

const EmptyContainer = styled.div`
  height: 200px;
`;

class HeaderPhoto extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.goNextSlide = this.goNextSlide.bind(this);
    this.goPreviousSlide = this.goPreviousSlide.bind(this);
  }

  getUnbrandedHeaderPhoto() {
    const { landingVersion } = this.props;
    if (landingVersion === 'menSuitVersion') {
      return menSuitHeaderPhoto;
    } if (landingVersion === 'motherVersion') {
      return motherHeaderPhoto;
    } if (landingVersion === 'embroidery') {
      return embroideryHeaderPhoto;
    }
    return isMobile() ? headerPhotoMobile : header1;
  }

  getHeaderPhoto() {
    switch (process.env.GATSBY_BRAND) {
      case 'MADURA':
        return maduraHeaderPhoto;
      case 'SEZANE':
        return sezaneHeaderPhoto;
      case 'GALERIES_LAFAYETTE':
      case 'GALERIES_LAFAYETTE_LYON':
        return galeriesLafayetteHeaderPhoto;
      case 'SESSUN':
        return sessunHeaderPhoto;
      default:
        return this.getUnbrandedHeaderPhoto();
    }
  }

  goPreviousSlide() {
    this.track.prev();
  }

  goNextSlide() {
    this.track.next();
  }

  render() {
    if (process.env.GATSBY_BRAND) {
      return (
        <RelativePosition>
          <HeaderPhotoComponent
            src={this.getHeaderPhoto()}
            alt="Cliente portant une jupe retouchée"
          />
          <Container>
            <Column>
              {featureSwitch('home_hideHeaderTitle')
                ? <EmptyContainer />
                : (
                  <H1Container1>
                    <H1 navy={false}>
                      <Span1>Votre partenaire retouche</Span1>
                    </H1>
                  </H1Container1>
                )}
              <SubContainer moreMarginTop>
                <Link
                  to={routesMap.Step1.url}
                  onClick={() => trackEvent('click', 'reserver_block-home')}
                >
                  <ButtonContainer margin="0px">
                    <StyledButton navy={false} noBorder>Prendre rdv</StyledButton>
                  </ButtonContainer>
                </Link>
              </SubContainer>
            </Column>
          </Container>
        </RelativePosition>
      );
    }

    return (
      <RelativePosition>
        <ViewPager tag="main" style={viewPagerStyle}>
          <Frame className="frame">
            <Track
              ref={(c) => { this.track = c; }}
              viewsToShow={1}
              swipe={this.props.items.length !== 1}
              className="track"
            >
              {this.props.items.map((item, index) => (
                <View className="view" key={item.imageAlt}>
                  <ProgressiveImage
                    src={isMobile() ? item.imageMobile.publicURL : item.image.publicURL}
                    placeholder={isMobile() ? item.imageLowMobile.publicURL : item.imageLow.publicURL}
                  >
                    {(src) => <Img alt={item.imageAlt} src={src} />}
                  </ProgressiveImage>
                  <Container>
                    <StyledRow alignItems="center">
                      <ControlContainer>
                        <ControlImg
                          alt="Photo précédente de retoucherie"
                          src={index !== 0 ? arrowLeft : arrowEmpty}
                          onClick={this.goPreviousSlide}
                        />
                      </ControlContainer>
                      <Column>
                        {item.title
                          && (
                            <H1Container1>
                              <H1 navy={item.isNavy}>
                                <Span1>{item.title}</Span1>
                              </H1>
                            </H1Container1>
                          )}
                        <SubContainer moreMarginTop={!item.title}>
                          <Span2 navy={item.isNavy}>{toFormattedText(item.subTitle)}</Span2>
                          <Link
                            to={routesMap.Step1.url}
                            onClick={() => trackEvent('click', 'reserver_block-home')}
                          >
                            <ButtonContainer margin="0px">
                              <StyledButton navy={item.isNavy} noBorder={!item.isNavy}>Prendre rdv</StyledButton>
                            </ButtonContainer>
                          </Link>
                        </SubContainer>
                      </Column>
                      <ControlContainer right>
                        <ControlImg
                          alt="Photo suivante d'une maison de couture"
                          src={index !== this.props.items.length - 1 ? arrowRight : arrowEmpty}
                          onClick={this.goNextSlide}
                        />
                      </ControlContainer>
                    </StyledRow>
                  </Container>
                </View>
              ))}
            </Track>
          </Frame>
        </ViewPager>
      </RelativePosition>
    );
  }
}

HeaderPhoto.propTypes = {
  landingVersion: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      subTitle: PropTypes.string.isRequired,
      image: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
      imageLow: PropTypes.shape({
        publicURL: PropTypes.string.isRequired,
      }).isRequired,
      imageAlt: PropTypes.string.isRequired,
      isNavy: PropTypes.bool.isRequired,
    }),
  ).isRequired,
};

HeaderPhoto.defaultProps = {
  landingVersion: null,
};

export default HeaderPhoto;
